<template>
  <v-dialog 
  :v-model="v-model"
    :width="width">
      <v-card class="pa-6">       
        <slot></slot>
      </v-card>
    </v-dialog>
</template>

<script>

import VDialog from '../../plugins/vuetify';
export default {
  name: "VaDialog",
  extends: VDialog,
  props: {   
    width: {
      type: Number,
      default: 500,
    }, 
  },
};
</script>
