<template>
    <v-app-bar app clipped-left color="var(--primary)" elevation="0">
        <v-app-bar-nav-icon class="d-block d-md-none" color="var(--white)"
            @click="$emit('open-drawer', !drawer)"></v-app-bar-nav-icon>
        <v-container>
            <h4 v-if="topbartitle" class="text-white">{{ topbartitle }}</h4>
            <h4 v-else class="text-white">Iedereen met een glimlach naar het werk</h4>

        </v-container>
    </v-app-bar>
</template>





<script>
export default {
    props: {
        topbartitle: {
            type: String,
            required: false,
        },
        drawer: Boolean
    },
    data: () => ({
    }),
    created() {
        if (window.innerWidth > 768) {
            this.$emit('open-drawer', true)
        }
    },
};
</script>
