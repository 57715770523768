<template>
	<v-sheet tabindex="0" :color="dragging ? 'var(--neutral-200)' : 'var(--neutral-100)'" class="file-input" :style="{ height: `${height}px` }" @dragenter="dragging = true" @dragleave="dragging = false" @drag="change">
		<div class="drop-label">
			<div v-if="icon === 'file'">
				<v-icon color="var(--neutral-200)" x-large icon="mdi-upload"></v-icon>				
			</div>
			<div v-if="icon === 'image'">
				
				<svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
					<path d="M4.49951 35.999C3.39951 35.999 2.45785 35.6074 1.67451 34.824C0.891178 34.0407 0.499512 33.099 0.499512 31.999V3.99902C0.499512 2.89902 0.891178 1.95736 1.67451 1.17402C2.45785 0.39069 3.39951 -0.000976562 4.49951 -0.000976562H32.4995C33.5995 -0.000976562 34.5412 0.39069 35.3245 1.17402C36.1078 1.95736 36.4995 2.89902 36.4995 3.99902V31.999C36.4995 33.099 36.1078 34.0407 35.3245 34.824C34.5412 35.6074 33.5995 35.999 32.4995 35.999H4.49951ZM4.49951 31.999H32.4995V3.99902H4.49951V31.999ZM6.49951 27.999H30.4995L22.9995 17.999L16.9995 25.999L12.4995 19.999L6.49951 27.999Z" fill="#DFDFDF"/>
					</svg>		
			</div>
			<span class="mt-3">Kies bestand of sleep en plaats</span>
		</div>
		<input type="file" :multiple="multiple" :accept="accept" @change="change" />
	</v-sheet>
</template>

<script>
export default {
	props: {
		height: String,
		accept: String,
		type: String,
		multiple: Boolean,
		input: Function,
	},
	data: () => ({
		dragging: false,
		icon: 'image',
	}),
	methods: {
		change(e) {
			this.dragging = false
			if (!e.target.files || e.target.files.length === 0) return

			let files = []
			for (const file of e.target.files) {
				files.push(file)
			}
			this.input(files)
		}
	}
}
</script>

<style lang="scss" scoped>
.drop-label {
	display: flex;
	flex-direction: column;
	text-align: center;
}
.file-input {
	position: relative;
	display: grid;
	place-items: center;
	width: 100%;
	min-height: 200px;
	border-radius: var(--Radius--base, 20px);

	input {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
	}
}
</style>