<template>
  <v-navigation-drawer app location="left" v-model="editdrawer" width="316" height="100%" class="k-app-sidebar">
    <div class="k-app-sidebar-head">
      <k-app-logo width="170"></k-app-logo>
    </div>
    <k-spacing y="6"></k-spacing>
    <div class="k-app-sidebar-body">

      <v-list nav color="var(--primary)">
          <v-list-item link v-for="item in admin_items" :key="item.title" :to="item.to" rounded="xl"
            base-color="var(--neutral-700)">
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>       
              <v-list-item-title v-text="item.title"></v-list-item-title>           
          </v-list-item>
      </v-list>
    </div>
    <div class="k-app-sidebar-footer">
      <v-list lines="two">
    
      <v-list-item              
        :title="name"
        :subtitle="userMail"
      >
        <template v-slot:prepend>
          <v-avatar color="var(--primary-300)" size="48" :image="profilepicture"></v-avatar>         
        </template>

        <template v-slot:append>
          <v-btn
          @click.prevent="signout()"
            color="#696969"
            icon="mdi-logout"
            variant="text"
          ></v-btn>
        </template>
      </v-list-item>


    </v-list>

    

    </div>
  </v-navigation-drawer>
</template>

<script>

import { useMainStore } from "@/stores/store";
// import config from "@/config";
// import * as cookies from "js-cookie";
// import api from "../../../services/api";
export default {
  setup() {
    const mainStore = useMainStore();

    return {
      name: mainStore.user.firstname + ' ' + mainStore.user.lastname,
      profilepicture: mainStore.user.profilepicture,
      userMail: mainStore.user.email,
    }

  },
  data: () => ({

    editdrawer: false,
    admin_items: [
      {
        title: "Dashboard",
        icon: 'mdi-view-dashboard-outline',
        to: "/",
      },
      {
        title: "Activiteiten",
        icon: 'mdi-meditation',
        to: "/activiteiten",
      },
      {
        title: "Bedrijfspagina's",
        icon: 'mdi-briefcase-outline',
        to: "/bedrijfspaginas",
      },
      {
        title: "Accountgegevens",
        icon: 'mdi-account-circle-outline',
        to: "/accountgegevens",
      },
      {
        title: "Reviews",
        icon: 'mdi-message-outline',
        to: "/reviews",
      },
      {
        title: "Instellingen",
        icon: 'mdi-cog-outline',
        to: "/instellingen",
      },
    ],



    mini: true,
  }),
  watch: {
    drawer() {
      this.editdrawer = this.drawer;
    },
    editdrawer() {
      this.$emit('open-drawer', this.editdrawer)
    }
  },
  props: {
    drawer: Boolean
  },

  mounted() {
    this.editdrawer = this.drawer

  },
  methods: {
    redirect(item, type) {
      if (type == "link") {
        window.open(item, "_blank");
      } else {
        this.$router.push(item);
      }
    },

    signout() {
      //   api
      //     .put(`v1/auth/logout`, {
      //       refresh_token: cookies.get("gfo_refresh_token"),
      //     })
      //     .then((res) => {
      //       if (res.data.success) {
      //         cookies.remove("gfo_access_token", {
      //           domain: config.cookiedomain,
      //           path: "/",
      //         });
      //         cookies.remove("gfo_refresh_token", {
      //           domain: config.cookiedomain,
      //           path: "/",
      //         });
      //         cookies.remove("user", {
      //           domain: config.cookiedomain,
      //           path: "/",
      //         });
      //         location.replace(config.url.login);
      //       }
      //     })
      //     .catch((err) => {
      //       switch (err.response.status) {
      //         default:
      //           this.$toast.error("Oops, something went wrong");
      //           break;
      //       }
      //     });
    },
  },
};
</script>
<style>
.k-app-sidebar {

  align-content: space-between;
  height: 100%;
  display: flex;
}

.k-app-sidebar-head {
  padding-top: var(--spacing-6);
  padding-left: var(--spacing-6);
  padding-right: var(--spacing-3);
}

.k-app-sidebar-body {
  padding-left: var(--spacing-3);
  padding-right: var(--spacing-3);
}

.k-app-sidebar-footer {
  border-top: 1px solid var(--neutral-200, #DFDFDF);
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: var(--spacing-0)
}

.v-list-item__prepend .v-list-item__spacer {
  width: 16px !important;
}


.k-app-sidebar-footer .v-list-item-title{
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 600;
  color: var(--neutral-700, #4E4E4E);
}
.k-app-sidebar-footer .v-list-item-subtitle{
  color: var(--neutral-600, #696969);
font-family: Open Sans;
font-size: 1.2rem;
font-style: normal;
font-weight: 400;
line-height: 2.16rem
}
</style>