<template>
	<div>

		<k-fileupload v-if="!file" :input="input" />
		<div v-else>
			<cropper :src="image" ref="cropper" class="img-fluid" :stencil-props="{
				handlers: {},
				movable: false,
				scalable: false,
				aspectRatio: 1,
			}" :stencil-size="{
	width: 512,
	height: 512,
}" stencil-component="circle-stencil" image-restriction="stencil" />
		</div>


		<v-progress-linear indeterminate v-if="busy" />
		<v-btn elevation="0" v-if="file" class="btn btn-sm"  variant="outlined" color="var(--tertiary)" @click="file = null"> Wijzigen </v-btn>
<k-spacing y="5"></k-spacing>

		<!-- <v-btn class="btn mr-2" color="var(--tertiary)" variant="outlined" @click="dialog = false"
			:disabled="busy">Annuleren</v-btn>
		<v-btn class="btn text-white" color="var(--tertiary)" @click="submit" :disabled="busy">Opslaan</v-btn> -->

	</div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
// import 'vue-advanced-cropper/dist/style.css'

// import { mapGetters } from 'vuex'
// import api from "../services/api";
// import config from '../config'

export default {

	comments: { Cropper },
	props: {
		user: Number,
		employer: Object,
		type: String,
		settings: Object,
		advisor: Object
	},
	data: () => ({
		dialog: false,
		file: null,
		busy: false,
	}),
	computed: {
		// ...mapGetters({ token: 'gettoken', _user: 'getuser' }),
		// image() {
		// 	if (this.file !== null) {
		// 		return URL.createObjectURL(this.file)
		// 	} else {
		// 		return ''
		// 	}
		// }
	},
	methods: {
		input(e) {
			this.file = e[0]
		},
		async getBlob(canvas) {
			const blob = await new Promise((resolve) => canvas.toBlob(resolve))
			return blob
		},
		// 		submit() {
		// 			this.busy = true;
		// 			const { canvas } = this.$refs.cropper.getResult()
		// 			if (canvas) {
		// 				this.getBlob(canvas).then((blob) => {
		// 					const data = new FormData()
		// 					data.append('file_image', blob)
		// 					if(this.type === 'settings'){
		// 						api.post(`${config.url.api}/v1/settings/${this.user}/avatar`, data).then((res) => {
		// 						if (res.data.success) {
		// 							this.settings.profilepicture= res.data.r

		// 							// this.$toast.success('Profielfoto bijgewerkt')
		// 							this.dialog = false;
		// 						} else {
		// 							// this.$toast.error('Fout bij het bijwerken van de profielfoto')
		// 						}
		// 					})
		// 					}
		// 					else if(this.type === 'advisors'){
		// 						api.post(`${config.url.api}/v1/advisors/${this.user}/avatar`, data).then((res) => {
		// 						if (res.data.success) {

		//                                 this.advisor.profilepicture = res.data.r

		// 							// this.$toast.success('Profielfoto bijgewerkt')
		// 							this.dialog = false;
		// 						} else {
		// 							// this.$toast.error('Fout bij het bijwerken van de profielfoto')
		// 						}
		// 					}).catch(() => {
		// 						// this.$toast.error('Fout bij het bijwerken van de profielfoto')
		// 					})
		// 					}
		// 					else{
		// 					api.post(`${config.url.api}/v1/users/${this.user}/avatar`, data).then((res) => {
		// 						if (res.data.success) {
		//                             if(this.employer){
		// this.employer.profilepicture= res.data.r
		//                             }
		//                             else{
		//                                 this._user.profilepicture = res.data.r
		//                             }
		// 							// this.$toast.success('Profielfoto bijgewerkt')
		// 							this.dialog = false;
		// 						} else {
		// 							// this.$toast.error('Fout bij het bijwerken van de profielfoto')
		// 						}
		// 					}).catch(() => {
		// 						// this.$toast.error('Fout bij het bijwerken van de profielfoto')
		// 					})
		// 				}
		// 				})
		// 			}
		// 		},
	}
}
</script>
