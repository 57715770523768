<template>
	<v-sheet
	v-if="!status"
		tabindex="0"
		color="#C1C0BE"
		class="file-input rounded-lg"
		:style="{ height: `${height}px` }"
		@dragenter="dragging = true"
		@dragleave="dragging = false"
		@drag="onChange"
	>
		<div class="drop-label">
			<v-icon x-large color="#fff">far fa-image</v-icon>	
		</div>
		<input
			type="file"
			ref="image"
			accept="image/png,image/jpeg,image/webp"
			@change="onChange"
		/>
	</v-sheet>
	
</template>

<script>
export default {
	props: { height: String },
	mounted() {
  },

	data() {
		return { 			
			dragging: false };
	},
	methods: {
		onChange(e) {
			this.dragging = false;
			const file = e.target.files[0];
			this.$emit("input", file);
		},
	},
};
</script>

<style lang="scss" scoped>
.drop-label {
	display: flex;
	flex-direction: column;
}
.file-input {
	position: relative;
	display: grid;
	place-items: center;
	width: 100%;
	min-height: 130px;
	border-radius: 5px;
	margin-block: 10px;

	input {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
	}
}
</style>