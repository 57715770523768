<template>  
    <div :style="{ height: computedHeight, width: computedWidth }"></div>
</template>


<script>
export default {
  props: {   
    y: {
      type: String,
      required: false,
    },
    x: {
      type: String,
      required: false,
    },
   
  
  },
  computed: {
    computedHeight() {
      return `var(--spacing-${this.y})`;
    },
    computedWidth() {
      return `var(--spacing-${this.x})`;
    },
  },
};
</script>