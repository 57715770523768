<template>
    <v-dialog v-model="dialog_freelance_link" width="640" content-class="card text-center">
        <v-btn
            icon
            dark
            variant="text"
            class="dialog-close-btn"
            @click="close"
          >          
            <v-icon>mdi-close</v-icon>
          </v-btn>
          
        <h2>Kopiëer jouw link</h2>
        <p>Hier jouw unieke link. Kopieer het en stuur het naar de Freelancer.</p>
        <k-spacing y="6"></k-spacing>
        <v-snackbar :color="copysnackbar_status">
            <template v-slot:activator="{ props }">

                <v-text-field v-bind="props" variant="solo" :value="link" @input="updateLink"
                    append-inner-icon="mdi-content-copy" @click:append-inner="copy()"></v-text-field>
                <k-spacing y="6"></k-spacing>
                <div>
                    <v-btn v-bind="props" class="btn text-white" @click="copy" color="var(--tertiary)">
                        Kopieer link
                    </v-btn>
                </div>
            </template>
            {{ copysnackbar }}
        </v-snackbar>

    </v-dialog>
</template>

<script>

export default {
    props: {
        link: String,
    },


    data() {
        return {
            dialog_freelance_link: false,
            copysnackbar: "",
            copysnackbar_status: "",
        }
    },
    methods: {
        updateLink(newLink) {
            // Emit an input event to update the prop value
            this.$emit('update:link', newLink);
        },

        close() {            
                this.$emit('close');
            },

        async copy() {
            try {
                await navigator.clipboard.writeText(
                    `${this.link}`
                );
                this.copysnackbar = 'Link gekopieërd.'
                this.copysnackbar_status = 'var(--secondary)'
            } catch ($e) {
                this.copysnackbar = 'Fout bij het kopieëren van de link.'
                this.copysnackbar_status = 'var(--primary)'
            }
        },
    },
};


</script>