<template>
  <v-card
    v-bind="$attrs"
    :color="color"
    :elevation="elevation"
    :size="size"
    :block="block"
    :flat="flat"
    :text="text"
    :light="light"
    :dark="dark"
    :width="width"
    :min-width="min-width"
    :max-width="max-width"
    :height="height"
    :to="to"
  >
    <slot></slot>
  </v-card>
</template>

<script>


import VCard from '../../plugins/vuetify';
export default {
  name: "VaCard",
  extends: VCard,
  props: {
    color: {
      type: String,
      default: "",
    },
    elevation: {
      type: Number,
      default: 0,
    },
    light: String,
    to: String,
    width: String,
    height:String,
    dark:String,
    min:String,
    max:String,
    flat: String,
    block: Boolean,
    text: String,
    size: String,

  },



};
</script>
