<template>
  <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
        elevation="0"
          color="transparent"          
          v-bind="attrs"
          v-on="on"
        >
        <div :style="`width: 25px; height: 25px; border-radius: 100px; margin-right: 10px; background-color:${editcolor}`"></div> 
          {{title}}
        </v-btn>
      </template>
      <v-color-picker dot-size="28" mode="hexa" v-model="editcolor"></v-color-picker>
    </v-menu>


</template>

<script>
export default {
  props: {
    color: String,
    theme: String,
    title: String,
  },
 mounted(){
this.editcolor = this.color;
 },
  watch: {
    editcolor() {
      if (this.editcolor) {
        this.$emit("color", this.editcolor);
      }
    },
  },
  data: () => ({
    editcolor:'#FFFF'
  }),
};
</script>
