import { defineStore } from 'pinia'

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useMainStore = defineStore('store', {
    state: () => {
        return {
          // all these properties will have their type inferred automatically
          user: {
			id: -1,
			firstname: 'Jane',
			lastname: 'Smith',
			phonenumber: '',
			email: 'jane@dfm.nl',
			role: 0,
			profilepicture: 'https://s3-alpha-sig.figma.com/img/6733/257c/d98b75d937ebf9bc0e2a51c9518f7ab5?Expires=1703462400&Signature=frmgfcn4OTxCUZ7MYoapy5cXz9O12CoEWLttagSGviKhvtZqu437T77I9XcSE8lYC9Y8MQB361UTvVFCFJvTrizBF~6NNheTJHdgQ3DbqZ4gNtQ8Tx1pv9M~Nlvb2oy3XDjx~Ek1OL9ll-CUQVuqD-6pIy9mm6MhWRa8ter0udp02iFtrOqB4vO9ueHV7rLFGQGJFZi9ueF2PflCtsAMHCOe4HA9UbHqrgQcKwqSsMGoA80zSHOEHJM3n4RcMkOIPVrWBnt2GfE~Hg5eFvDgs4NFfApuEvTlJ22wQbEOJTN0mjR6WBhkA5hJyPLvz46q5MmOawlaxXZlqSH818uRXA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
			title: '',
			bio: '',
			dateofbirth: '',
			register_date: 0,
			perms: ['userlevel_admin'],

		},
        }
      },
    })


